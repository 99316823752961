<template>
  <v-container>
    <v-row style="width: 100%"
      ><v-col>{{ $t("consent-form.edit_title") }}</v-col></v-row
    >

    <form
      action
      data-vv-scope="consent-form"
      @submit.prevent="validateForm('consent-form')"
    >
      <v-row style="width: 100%">
        <v-row style="width: 100%"
          ><v-col cols="6" class="py-0">
            <label style="font-size: 12px">{{
              $t("consent-form.section_title_new")
            }}</label>
            <v-text-field
              outlined
              dense
              v-model="section.name"
              v-validate="'required|min:1'"
              color="secondary"/></v-col
        ></v-row>
        <draggable
          v-if="section.modules.length > 0"
          style="width: 100%"
          v-model="section.modules"
          ghost-class="ghost"
        >
          <v-row
            class="fila"
            style="width: 100% !important"
            v-for="(modules, i) in section.modules"
            :key="i"
          >
            <v-col cols="12" md="3" class="py-0">
              <label v-text="$t('consent-form.module')"/>
              <v-select
                :items="items"
                v-model="modules.type"
                single-line
                dense
                outlined
                hide-details
              ></v-select></v-col
            ><v-col cols="8" class="pa-0" v-if="modules.type == 'texto'">
              <v-row
                ><v-col class="py-0" cols="8"
                  ><v-text-field
                    style="width: 340px"
                    hide-details
                    v-model="modules.title"
                    placeholder="Título"
                  ></v-text-field>
                  <v-text-field
                    outlined
                    dense
                    disabled
                    style="width: 340px"
                    v-validate="'required|min:1'"
                    color="secondary"
                    data-vv-validate-on="blur"
                  ></v-text-field
                ></v-col>
                <v-col cols="4" class="py-0">
                  <label v-text="$tc('size')" />
                  <v-select
                    :items="sizes"
                    v-model="modules.size"
                    item-value="value"
                    single-line
                    style="width: 170px"
                    dense
                    outlined
                    hide-details
                  ></v-select>

                  <v-checkbox v-model="modules.required" class="required">
                    <template v-slot:label="">
                      <span> Obligatorio </span>
                    </template>
                  </v-checkbox></v-col
                ></v-row
              >
            </v-col>
            <v-col cols="8" class="pa-0" v-if="modules.type == 'texto largo'">
              <v-row
                ><v-col class="py-0"
                  ><v-text-field
                    style="margin-top: -10px; width: 340px"
                    class="mb-1"
                    v-model="modules.title"
                    hide-details
                    placeholder="Título"
                  ></v-text-field>
                  <v-textarea
                    style="border-radius: 10px; width: 340px"
                    class="pt-1"
                    outlined
                    disabled
                    rows="2"
                  >
                  </v-textarea
                ></v-col>
                <v-col cols="4" class="py-0">
                  <label v-text="$tc('size')" />
                  <v-select
                    :items="sizes"
                    v-model="modules.size"
                    item-value="value"
                    single-line
                    style="width: 170px"
                    dense
                    outlined
                    hide-details
                  ></v-select>

                  <v-checkbox v-model="modules.required" class="required">
                    <template v-slot:label="">
                      <span> Obligatorio </span>
                    </template>
                  </v-checkbox></v-col
                ></v-row
              >
            </v-col>
            <v-col cols="8" class="pa-0" v-if="modules.type == 'check'">
              <v-row>
                <v-col class="py-0"
                  ><v-checkbox class="check">
                    <template v-slot:label="">
                      <v-text-field
                        class="pa-0"
                        hide-details
                        style="width: 300px"
                        v-model="modules.title"
                        placeholder="Título"
                      ></v-text-field>
                    </template>
                  </v-checkbox>
                </v-col>
                <v-col cols="4" class="py-0">
                  <label v-text="$tc('size')" />
                  <v-select
                    :items="sizes"
                    v-model="modules.size"
                    item-value="value"
                    single-line
                    style="width: 170px"
                    dense
                    outlined
                    hide-details
                  ></v-select>

                  <v-checkbox v-model="modules.required" class="required">
                    <template v-slot:label="">
                      <span> Obligatorio </span>
                    </template>
                  </v-checkbox></v-col
                >
              </v-row>
            </v-col>
            <v-col cols="8" class="pa-0" v-if="modules.type == 'espacio'">
              <v-row>
                <v-col class="py-0"> </v-col>
                <v-col cols="4" class="py-0">
                  <label v-text="$tc('size')" />
                  <v-select
                    :items="sizes"
                    v-model="modules.size"
                    item-value="value"
                    single-line
                    style="width: 170px"
                    dense
                    outlined
                    hide-details
                  ></v-select>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="8" class="pa-0" v-if="modules.type == 'select'">
              <v-row>
                <v-col cols="3"
                  ><v-text-field
                    class="select"
                    v-model="modules.title"
                    hide-details
                    placeholder="Título"
                  ></v-text-field>
                  <v-select
                    :items="modules.option"
                    single-line
                    dense
                    outlined
                    hide-details
                  ></v-select
                ></v-col>
                <v-col cols="5">
                  <label v-text="'Selectores'" />
                  <v-text-field
                    outlined
                    dense
                    @input="selectores(i)"
                    v-model="modules.optionText"
                    placeholder="Hay que separar cada selector con una ,"
                    v-validate="'required|min:1'"
                    color="secondary"
                    data-vv-validate-on="blur"
                  ></v-text-field>
                </v-col>
                <v-col cols="4" class="py-0">
                  <label v-text="$tc('size')" />
                  <v-select
                    :items="sizes"
                    v-model="modules.size"
                    item-value="value"
                    single-line
                    style="width: 170px"
                    dense
                    outlined
                    hide-details
                  ></v-select>

                  <v-checkbox v-model="modules.required" class="required">
                    <template v-slot:label="">
                      <span> Obligatorio </span>
                    </template>
                  </v-checkbox></v-col
                >
              </v-row>
            </v-col>
            <v-col cols="8" class="pa-0" v-if="modules.type == 'fecha'">
              <v-row
                ><v-col class="py-0">
                  <v-text-field
                    style="width: 340px"
                    hide-details
                    v-model="modules.title"
                    placeholder="Título"
                  ></v-text-field>
                  <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        hide-details
                        outlined
                        dense
                        style="width: 340px"
                        :value="date ? $d(new Date(date), 'date2digits') : ''"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        ><template v-slot:prepend-inner>
                          <!--Aqui va el v-icon -->
                          <v-icon
                            small
                            class="pt-0 pb-1"
                            style="margin-top: 6px"
                            color="primary"
                            >$appointments</v-icon
                          >
                        </template></v-text-field
                      >
                    </template>
                    <v-date-picker
                      first-day-of-week="1"
                      v-model="date"
                      no-title
                      scrollable
                      color="primary"
                      @input="menu = false"
                    >
                    </v-date-picker> </v-menu
                ></v-col>
                <v-col cols="4" class="py-0">
                  <label v-text="$tc('size')" />
                  <v-select
                    :items="sizes"
                    v-model="modules.size"
                    item-value="value"
                    single-line
                    style="width: 170px"
                    dense
                    outlined
                    hide-details
                  ></v-select>

                  <v-checkbox v-model="modules.required" class="required">
                    <template v-slot:label="">
                      <span> Obligatorio </span>
                    </template>
                  </v-checkbox></v-col
                ></v-row
              >
            </v-col>
            <v-col
              cols="1"
              :md="section.modules.length < 1 ? '2' : '1'"
              class="py-0"
            >
              <v-btn icon @click="deleteModule(i)"
                ><v-icon>mdi-close</v-icon></v-btn
              >
            </v-col>
          </v-row>
        </draggable>
        <v-row>
          <v-col>
            <v-btn
              style="
                width: 100px !important;
                color: #363533;
                height: 25px;
                padding-top: 10px !important;
              "
              elevation="0"
              @click="addModule"
            >
              <v-icon>mdi-plus</v-icon>{{ $t("add", { name: "" }) }}
            </v-btn>
          </v-col>
        </v-row>
      </v-row>
      <v-row>
        <v-col cols="2">
          <v-btn
            @click="saveSection"
            style="
              width: 100px !important;
              color: #363533;
              height: 25px;
              padding-top: 10px !important;
            "
            elevation="0"
          >
            <v-icon style="margin-right: 5px" size="14px">$save</v-icon>
            {{ $t("save", { name: $t("") }) }}
          </v-btn>
        </v-col>
        <v-col cols="2">
          <v-btn
            elevation="0"
            @click="dialog = true"
            style="
              width: 100px !important;
              color: #363533;
              height: 25px;
              padding-top: 10px !important;
            "
            v-text="$t('preview')"
          />
        </v-col>
      </v-row>
    </form>
    <v-dialog
      :width="$store.getters['auth/isStudio'] ? '80%' : '500'"
      v-model="dialog"
    >
      <v-card class="popupCard">
        <v-card-title
          style="text-decoration: underline; text-transform: uppercase"
          >{{ $t("preview") }}
        </v-card-title>
        <div class="close">
          <v-btn icon @click="dialog = false" small>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <base-material-card>
          <v-stepper v-model="e1" alt-labels>
            <v-stepper-header style="height: 150px; font-size: 14px">
              <v-stepper-step step="1" :complete="e1 > 1">
                <div
                  style="
                    text-align: center;
                    color: var(--v-primary-base);
                    text-transform: uppercase;
                    height: 80px;
                  "
                >
                  {{ $t("consent-form.general_info") }}
                </div>
              </v-stepper-step>
              <v-divider style="margin-top: 50px !important">></v-divider>
              <v-stepper-step step="2" :complete="e1 > 2">
                <div
                  style="
                    text-align: center;
                    color: var(--v-primary-base);
                    text-transform: uppercase;
                    width: 120%;
                    margin-left: -10%;
                  "
                >
                  {{ $t("consent-form.personal_information") }}
                </div>
              </v-stepper-step>
              <v-divider style="margin-top: 50px !important">></v-divider>
              <v-stepper-step step="3" :complete="e1 > 3">
                <div
                  style="
                    text-align: center;
                    color: var(--v-primary-base);
                    text-transform: uppercase;
                    width: 120%;
                    margin-left: -10%;
                  "
                >
                  {{ $t("consent-form.image_right") }}
                </div>
              </v-stepper-step>
              <div v-if="section != null" class="ma-pa-0"></div>
              <v-divider style="margin-top: 50px !important">></v-divider>
              <v-stepper-step step="4" :complete="e1 > 4">
                <div
                  style="
                    text-align: center;
                    color: var(--v-primary-base);
                    text-transform: uppercase;
                    width: 120%;
                    margin-left: -10%;
                  "
                >
                  {{ section.name }}
                </div>
              </v-stepper-step>
            </v-stepper-header>
            <v-stepper-content step="4"
              ><VisualForm
                :edit="true"
                :sizes="sizes"
                :section="section"
              ></VisualForm
            ></v-stepper-content>
          </v-stepper>
        </base-material-card>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import draggable from "vuedraggable";

export default {
  name: "EditConsentForm",

  data() {
    return {
      e1: 1,
      menu: false,
      dialog: false,
      date: null,
      modulo: null,
      edit: true,
      selects: [],
      items: ["select", "texto", "texto largo", "fecha", "check", "espacio"],
      sizes: [
        { text: "1/1", value: "12" },
        { text: "1/2", value: "6" },
        { text: "1/3", value: "4" },
        { text: "1/4", value: "3" },
      ],
      section: {
        name: null,
        modules: [
          {
            title: null,
            type: null,
            option: [],
            optionText: null,
            required: false,
            size: null,
          },
        ],
      },
    };
  },

  mounted() {
    this.fetchSetting();
    this.section.modules = [];
  },
  components: {
    draggable,
    VisualForm: () =>
      import("@/components/user/settings/profile/VisualConsentForm"),
  },

  methods: {
    ...mapActions("users", ["settingUpdate", "getSetting"]),

    selectores(i) {
      this.section.modules[i].option = this.section.modules[i].optionText.split(
        ","
      );
      //console.log(this.section.modules[i].option);
    },
    fetchSetting() {
      this.getSetting({ key: "cf_section" }).then((boxes) => {
        //console.log(boxes.data.value);
        this.section = JSON.parse(boxes.data.value);

        //console.log(this.section.modules[0].size);
        this.loading = false;
      });
    },
    addModule() {
      this.section.modules.push({ size: "12", type: "texto" });
    },
    deleteModule(index) {
      this.section.modules.splice(index, 1);
    },
    saveSection() {
      this.settingUpdate({
        key: "cf_section",
        value: this.section,
        json: true,
      }).then((data) => {
        //console.log(data);
      });
    },
  },
};
</script>

<style lang="sass">
.ghost
  opacity: 0.5
  background: var(--v-primary-base)

.popupCard

  color: red !important
  width: 100% !important
  background-color: var(--v-gris3-base) !important
  .v-stepper--alt-labels .v-stepper__step

  .close .v-btn
    top: 0
    right: 0px
    position: absolute

    .v-icon
      color: var(--v-primary-base) !important
      position: absolute

  .v-card__title
    background-color: var(--v-gris3-base)
  .v-text-field__details
    display: none
.fila
  width: 100%
  border-bottom: 2px dashed var(--v-gris1-base)
  height: 120px
.check
  margin-top: 10px !important
  .v-input--selection-controls__input
    margin-top: 20px !important
.required
  margin-top: 5px !important
  label
    padding-top: 2px
  .v-input--selection-controls__input
    margin-top: 0px !important
.select
  margin-top: 0px !important
  padding-top: 0px !important
#imp
label
  display: block
  height: 25px
</style>
